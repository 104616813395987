import React, { createContext, useState, useContext } from 'react';

// Create the context
const TriviaContext = createContext();
function removeNewlinesAndHashtagsAtEnd(sentence) {
  return sentence.replace(/[\n#]+$/g, '');
}

// Custom hook for easy context consumption
export const useTrivia = () => useContext(TriviaContext);

// Provider component
export const TriviaProvider = ({ children }) => {
  let uniqueIndex = React.useRef(0);

  const [isLoading, setIsLoading] = useState(false);
  const [triviaQuestions, setTriviaQuestions] = useState([]);
  const [triviaQuestionCache, setTriviaQuestionsCache] = useState([
    { index: 1, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 2, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 3, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 4, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 5, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 6, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 7, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 8, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 9, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
    { index: 10, question: null, options: ["", "", "", ""], answere: null, open: false, custom: true },
  ])
  const [excluded, setExcluded] = useState([])
  let [rendered, setRendered] = useState(1);
  const [other, setOther] = useState({})
  const [error, setError] = useState(null);
  const findMaxIndex = (item) =>{
    let max = 0
    item.map((item, _) =>{
      if(item.index > max){
        max = item.index
      }
    })
    return max
  }

  const handleGenerateTrivia = async (prompt, qnumber, page) => {
    let current = await findMaxIndex(triviaQuestionCache)
    setIsLoading(true);
    setError(null);
    try {
      let headersList = {
        "Content-Type": "application/json"
      }
      let bodyContent = JSON.stringify({
        "prompt": prompt,
        "exclude": excluded,
        "expect": 10,
        "count": triviaQuestionCache.length
      });
      let response = await fetch("https://radiant-basin-54000-27b04cfd1733.herokuapp.com/api/trivia/generate-trivia", {
        method: "POST",
        body: bodyContent,
        headers: headersList
      });
      let data = await response.text();
      let newData = JSON.parse(data);
      let temp = newData.questions
      let ToSet = []
      let cheker = triviaQuestionCache
      temp.forEach((item, index) => {
        const existingQuestion = cheker.find(question => question.question === null);
        if (existingQuestion) {
          cheker = cheker.filter(item => item.index != existingQuestion.index)
          const updatedQuestion = { ...item, index: existingQuestion.index, open: false };
          ToSet.push(updatedQuestion);
        } else {
          const newQuestion = { ...item, index: current + 1, question: removeNewlinesAndHashtagsAtEnd(item.question), open: false };
          ToSet.push(newQuestion);
          current  = current + 1;
        }
      });
      // Update the state with the new data
      setTriviaQuestions(ToSet);
      setTriviaQuestionsCache(prev => [...prev.filter(question => question.question !== null), ...ToSet]);
      let toExclude = temp.map(question => question.question);
      setExcluded(oldExclude => [...oldExclude, ...toExclude]);
      setRendered(rendered + 1);
      setOther(newData);
    } catch (e) {
      console.error('There was an error fetching the trivia questions:', e);
      setError(e); // Set error state
    }
    setIsLoading(false);
  };

  const openToggle = (index) => {
    setTriviaQuestionsCache(triviaQuestionCache.map((item, idx) => {
      if (item.index === index) {
        let newItem = item
        newItem.open = item.open ? false : true
        return newItem
      } else {
        return item;
      }
    }))
  }


  const updateQuestionInCache = (questionId, updatedQuestionInfo) => {
    const updatedQuestions = triviaQuestionCache.map((item, idx) => {
      if (item.index === questionId) {
        return { ...item, question: updatedQuestionInfo };
      }
      return item;
    });
    setTriviaQuestionsCache(updatedQuestions);
  };

  // provided the options are arrays that can be accessed with an index
  const updateGiveQuestionOptions = (questionId, optionIndex, updatedOption) => {
    const updatedQuestions = triviaQuestionCache.map((item, idx) => {
      if (item.index === questionId) {
        let optionData = item.options
        optionData[optionIndex] = updatedOption
        return { ...item, options: optionData };
      }
      return item;
    });
    setTriviaQuestionsCache(updatedQuestions);
  }

  const deleteGiveQuestionOptions = (questionId, optionIndex) => {
    const updatedQuestions = triviaQuestionCache.map((item, idx) => {
      if (item.index === questionId) {
        let optionData = item.options
        delete optionData[optionIndex]
        return { ...item, options: optionData };
      }
      return item;
    });
    setTriviaQuestionsCache(updatedQuestions);

  }

  const changeAnswere = (questionId, updatedAnswereID) => {
    let updatedQuestions = triviaQuestionCache.map((item, index) => {
      if (item.index === questionId) {
        return { ...item, answer: item.options[updatedAnswereID] }
      }
      return item
    })
    setTriviaQuestionsCache(updatedQuestions)
  }

  const mergeArraysAndDownload = () => {
    const newTriviaForJson = triviaQuestionCache.map((item, key) => {
      delete item['open']
      delete item['index']
      return item
    })

    // Prepare the merged array for download by wrapping it in an object
    const dataToDownload = { data_id: '', data: { questions: newTriviaForJson } };

    // Convert the data to a string
    const dataStr = JSON.stringify(dataToDownload, null, 2);
    // Create a blob object representing the data
    const blob = new Blob([dataStr], { type: 'application/json' });
    // Create a URL for the blob
    const url = URL.createObjectURL(blob);
    // Create an anchor element and set its href to the blob URL
    const link = document.createElement('a');
    link.href = url;
    link.download = 'triviaQuestions.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const deleteQuestion = (questionId) => {
    let updatedQuestions = triviaQuestionCache.filter((item, _) => {
      return item.index != questionId
    })
    setTriviaQuestionsCache(updatedQuestions)
  }

  const handleCustomInputClose = (id) => {
    setTriviaQuestionsCache(
      triviaQuestionCache.filter((item) => {
        if (item.index == id) {
          item.open = false;
          return item;
        } else {
          return item;
        }
      })
    );
  };
  // On Click of the custom events a new array is appended to the trivial array and allowed to be accessed
  const handleCustomInputOpen = (id) => {
    setTriviaQuestionsCache(
      triviaQuestionCache.filter((item) => {
        if (item.index == id) {
          item.open = !item.open;
          return item;
        } else {
          return item;
        }
      })
    );
    // setTriviaQuestionsCache((prev) => ([...prev, ...customToSet]));

  };

  const addtotriviacachecustom = () => {
    let length = triviaQuestionCache.length
    let newAddtion = triviaQuestionCache[length - 1].index
    setTriviaQuestionsCache([...triviaQuestionCache, { index: newAddtion + 1, question: null, options: ['', '', '', ''], answere: null, open: false, custom: true }])
  }

  // change the first question
  const handleCustomQuestionChange = (e, items) => {
    setTriviaQuestionsCache(triviaQuestionCache.filter((item) => {
      if (item.index === items.index) {
        item.question = e.target.value;
        return item;
      } else {
        return item;
      }

    }))
  };

  const handleCustomQuestionImageAdd = (e, items) => {
    setTriviaQuestionsCache(triviaQuestionCache.filter((item) => {
      if (item.index === items.index) {
        item.imageUrl = e;
        return item;
      } else {
        return item;
      }

    }))
  };

  const handleCustomQuestionImageRemove = (items) => {
    setTriviaQuestionsCache(triviaQuestionCache.filter((item) => {
      if (item.index === items.index) {
        item.imageUrl = null;
        return item;
      } else {
        return item;
      }

    }))
  };

  const handleCustomOptionAddition = (items) => {
    setTriviaQuestionsCache(
      triviaQuestionCache.filter((item) => {
        if (item.index == items.index) {
          let temp = [...item.options];
          temp.push("");
          item.options = temp;
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const onCustomQuestionOptionData = (e, i, items) => {
    setTriviaQuestionsCache(
      triviaQuestionCache.filter((item) => {
        if (item.index == items.index) {
          item.option[i] = e.target.value;
          return item;
        } else {
          return item;
        }
      })
    );
  };
  const onCustomQuestionAnswere = (e, items) => {
    setTriviaQuestionsCache(
      triviaQuestionCache.filter((item) => {
        if (item.index == items.index) {
          item.answere = e;
          return item;
        } else {
          return item;
        }
      })
    );
  };
  const handleCustomOptionRemove = (i, items) => {
    setTriviaQuestionsCache(
      triviaQuestionCache.filter((item) => {
        if (item.index == items.index) {
          let temp = item.options.filter((choice, m) => {
            return m !== i;
          });
          item.option = temp;
          return item;
        } else {
          return item;
        }
      })
    );
  };


  const handleCustomInputOpenAll = () => {
    // setCustomOpen(!customOpen);
    setTriviaQuestionsCache(
      triviaQuestionCache.filter((item) => {
        if (true) {
          item.open = item.open ? false : true;
          return item;
        }
      })
    );
  };
  const handleRemove = async (index) => {
    let newData = triviaQuestionCache.filter(item => {
      if (item.index !== index) {
        return item
      }
    })
    setTriviaQuestionsCache(newData)
  };

  const handleAdditionFromText = async (data) => {
    let ToSet = []
    let i = triviaQuestionCache[triviaQuestionCache.length - 1].index

    data.map((item, index) => {
      let temp = { ...item, index: index + 1 + i, open: false }
      ToSet.push(temp);
    })
    setTriviaQuestionsCache((prev) => ([...prev, ...ToSet]));


  }

  const editGlobalTrivaquestions = (data) => {
    setTriviaQuestionsCache(data);

  }

  return (
    <TriviaContext.Provider value={{
      isLoading,
      triviaQuestions,
      handleGenerateTrivia,
      error,
      other,
      excluded,
      triviaQuestionCache,
      setTriviaQuestionsCache,
      updateQuestionInCache,
      updateGiveQuestionOptions,
      deleteQuestion,
      deleteGiveQuestionOptions,
      changeAnswere,
      mergeArraysAndDownload,
      openToggle,
      handleCustomInputOpen,
      handleCustomInputClose,
      handleCustomOptionRemove,
      onCustomQuestionAnswere,
      onCustomQuestionOptionData,
      handleCustomQuestionChange,
      handleCustomOptionAddition,
      handleCustomInputOpenAll,
      handleRemove,
      addtotriviacachecustom,
      handleAdditionFromText,
      editGlobalTrivaquestions,
      handleCustomQuestionImageAdd,
      handleCustomQuestionImageRemove
    }}>
      {children}
    </TriviaContext.Provider >
  );
};

import React, { useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

export default function Index() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const goToHome = async () => {
    await navigate("/"); // Replace '/home' with your target route
    window.location.reload()
  };
  function saveToLocalStorage(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Failed to save data", error);
    }
  }

  const ValidateUser = async (token) => {
    setLoading(true);
    try {
      var data = JSON.stringify({
        access_token: token,
        provider: "google",
        fcm_token: "fcm token",
      });

      var config = {
        method: "post",
        url: "https://klive-main-backend-dev-b58df3b26789.herokuapp.com/api/auth/signin",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiaWRlbnRpdHkiLCJ1c2VyX2lkIjoiOGY0OTFjMDMtMjcyZi00OThlLWE5ZmEtODFkNTQzNDE0MGFmIiwiZW1haWwiOiJhYmViZUBnbWFpbC5jb20iLCJyb2xlIjoyLCJpYXQiOjE3MTMzNTA4MzgsImV4cCI6MTcxMzYxMDAzOH0.O9mtRPL5BupgZZZ-YcZd4C7x-2x8Xtqk5xlWlzteqkc",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          saveToLocalStorage("logged_in", response.data);
          setLoading(false);
          goToHome();
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {}, []);

  return (
    <div className="flex flex-row min-h-[100vh] justify-center items-center">
      {loading ? (
        <Spin size={20} />
      ) : (
        <div className="bg-[#1A2028] shadow-xl w-[80%] h-[70vh] flex flex-col items-center justify-between p-10 rounded-xl">
          <h1 className="text-white text-5xl text-center max-w-[40%] font-bold">
            Welcome back to Beemi
          </h1>
          <h3 className="text[#747474] max-w-[30%] py-2 text-[#747474] text-center">
            Jump In, Game On, Go Viral: Where Every Play Sparks a World of Fun!
          </h3>
          <div className="flex flex-col justify-between text-white mt-2">
            <GoogleOAuthProvider clientId="714084733873-rjd1he3cldpauh31o6po46ffvmieu16a.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  await saveToLocalStorage(
                    "cred",
                    credentialResponse.credential
                  );
                  await saveToLocalStorage(
                    "clientId",
                    credentialResponse.credential
                  );
                  await ValidateUser(credentialResponse.credential);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          </div>
          <div className="flex flex-col items-center py-10 mt-3">
            <h1 className="text-[#9C9C9C] max-w-[50%] text-center text-sm">
              By signing up, you agree to our{" "}
              <a className="text-[#0673DA]">Terms</a> ,{" "}
              <a className="text-[#0673DA]">Privacy Policy</a> and{" "}
              <a className="text-[#0673DA]">Cookies Policy</a> .
            </h1>
            <h1 className="text-[#9C9C9C] text-sm mt-10">
              already have an account? <a className="text-[#0673DA]">Sign Up</a>
            </h1>
          </div>
        </div>
      )}
    </div>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAR07PIIl-V4IKnEkXxz0WhXhcRO4BBz9w",
  authDomain: "trivia-beemi.firebaseapp.com",
  projectId: "trivia-beemi",
  storageBucket: "trivia-beemi.appspot.com",
  messagingSenderId: "904732873514",
  appId: "1:904732873514:web:1a8753a3c618ebfed5c453"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export { storage };
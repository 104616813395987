


// let env = 'production

// Producation configuration
const API_KEY_Staging = 'wP4ZqsQgNFJ6EM9aemhctX8VzvdrGnf5yK7CYbULHDRu3xpTSB'
const API_USER_ELEMENT_GAME_DATA_Staging = 'https://klive-main-backend-staging-81f57c738380.herokuapp.com/api/user-element-game-data'
const API_USER_UPLOAD_QUESTION_Staging = 'https://klive-main-backend-staging-81f57c738380.herokuapp.com/api/user-game-data'
const API_Staging = ''

const API_USER_ELEMENT_GAME_DATA_P = "https://klive-main-backend-production-dfc169d2a67f.herokuapp.com/api/user-element-game-data"
const API_USER_UPLOAD_QUESTION_P = "https://klive-main-backend-production-dfc169d2a67f.herokuapp.com/api/user-game-data"

const API_P = 'https://klive-main-backend-production-dfc169d2a67f.herokuapp.com/api/user-game-data/trivia-questions-custom'
const API_KEY_P = 'CcWPMwVQvUktRyYNTSeqZ5r2XDKjF94ALd7G8Hhbx6Jgz3psfB'

// Staging configuration
const API_USER_ELEMENT_GAME_DATA_S = "https://klive-main-backend-dev-b58df3b26789.herokuapp.com/api/user-element-game-data"
const API_USER_UPLOAD_QUESTION_S = "https://klive-main-backend-dev-b58df3b26789.herokuapp.com/api/user-element-game-data"


const API_S = 'https://klive-main-backend-production-dfc169d2a67f.herokuapp.com/api/user-game-data/trivia-questions-custom'
const API_KEY_S = 'fMnmHgaqcvs524DKhYGxuCbZWw7NXByLR6Fzt3TAEQdr9pkjPJ'




let mains = async () => {
    let env = await localStorage.getItem("evn");
    let API_USER_ELEMENT_GAME_DATA = null
    let API_USER_UPLOAD_QUESTION = null

    let API = null
    let API_KEY = null
    if (true) {
        console.log("Using Production environment")
        API_USER_ELEMENT_GAME_DATA = API_USER_ELEMENT_GAME_DATA_P
        API_USER_UPLOAD_QUESTION = API_USER_UPLOAD_QUESTION_P
        API = API_P
        API_KEY = API_KEY_P
        return {
            API_USER_ELEMENT_GAME_DATA,
            API_USER_UPLOAD_QUESTION,
            API,
            API_KEY
        }

    } 
}
export {mains}



// export de 


